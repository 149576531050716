import styled from 'styled-components'

export const MenuContainer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #010606;

  @media screen and (max-width: 768px){
    height: auto;
  }

  @media screen and (max-width: 480px){
    padding-top: 2rem;
    height: auto;
  }
`;

export const MenuWrapper = styled.div`
  background-color: #010606;
  padding: 1rem;

  @media screen and (max-width: 1000px){
  }

  @media screen and (max-width: 768px){
  }
`;

export const Img = styled.img`
  width: 90%;
  height: 100%;

  @media screen and (max-width: 784px) {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
  }
`;
// Little Seoul
export const TopLine = styled.h1`
  color: #faab02;
  font-size: 2.25rem;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  /* text-transform: uppercase; */
  margin-bottom: 16px;
  padding: 3rem 0 1rem 0;

  @media screen and (max-width: 784px) {
    font-size: 1.75rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1rem;
    padding: 0rem 0 0rem 0;
    margin-bottom: 0.5rem;
  }
`;

// Korean BBQ in Mansfield
export const SubTopLine = styled.h2`
  color: #faab02;
  font-size: 1.25rem;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  /* text-transform: uppercase; */
  margin-bottom: 16px;
  padding: 0rem 0 1rem 0;

  @media screen and (max-width: 784px) {
    font-size: 1.15rem;
  }

  @media screen and (max-width: 480px) {
    font-size: .75rem;
    padding: 0rem 0 0rem 0;
    margin-bottom: 0.5rem;
  }
`;

export const Heading = styled.h1`
  margin-bottom: 2rem;
  margin-top: 1rem;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: #fff;

  @media screen and (max-width: 784px) {
    font-size: 31px;
    padding: 0;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;


