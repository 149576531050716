import React from 'react'
// import { animateScroll as scroll } from 'react-scroll';

import { 
  FaFacebook, 
  FaInstagram, 
  // FaLinkedin, 
  // FaTwitter 
} from 'react-icons/fa';

import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  // FooterLink,
  SocialMedia,
  SocialMediaWrap,
  // SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  Aref,
  // iframeMap
} from './FooterElements'

const Footer = () => {
  // const toggleHome = () => {
  //   scroll.scrollToTop();
  // };
  
  return (
    <FooterContainer id="footer">
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Find Us</FooterLinkTitle>
                <Aref href="https://goo.gl/maps/YGYua2KuQaB12qB59">
                  <span className="address">3141 E Broad Street</span>
                  <br/>
                  <span className="address">Suite. 205</span>
                  <br/>
                  <span className="address">Mansfield, TX 76063</span>
                </Aref>
                <SocialMedia>
                <SocialMediaWrap>
                  <SocialIcons>
                    <SocialIconLink href="https://www.facebook.com/LittleSeoul.Mansfield/" target="_blank" aria-label="Facebook">
                      <FaFacebook style={{ marginRight: "20px" }}/>
                    </SocialIconLink>
                    <SocialIconLink href="https://www.instagram.com/littleseoul.mansfield/" target="_blank" aria-label="Instagram">                    
                    <FaInstagram />
                    </SocialIconLink>
                    </SocialIcons>
                </SocialMediaWrap>
              </SocialMedia>

              {/* <p>3136 East Broad St</p>
              <p>Suite. 205</p>
              <p>Mansfield, TX 76063</p> */}
              {/* <FooterLink to="/">How it works</FooterLink>
              <FooterLink to="/">Testimonials</FooterLink>
              <FooterLink to="/">Careers</FooterLink>
              <FooterLink to="/">Investors</FooterLink>
              <FooterLink to="/">Terms of Service</FooterLink> */}
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Contact Us</FooterLinkTitle>
              <p>Ph: 817-225-2720</p>
              {/* <FooterLink to="/">How it works</FooterLink>
              <FooterLink to="/">Testimonials</FooterLink>
              <FooterLink to="/">Careers</FooterLink>
              <FooterLink to="/">Investors</FooterLink>
              <FooterLink to="/">Terms of Service</FooterLink> */}
            </FooterLinkItems>
          </FooterLinksWrapper>

          {/* <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Map</FooterLinkTitle>
              <iframeMap>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3362.1421407871226!2d-97.09090558508983!3d32.57573310152901!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e8b92b5cc4e27%3A0x18bb55a7fe58a737!2sLittle%20Seoul!5e0!3m2!1sen!2sus!4v1614576867409!5m2!1sen!2sus" width="400" height="300" style={{border: '0' }} allowfullscreen="" loading="lazy" title="little tokyo google map"/>
              </iframeMap> */}
              {/* <FooterLink to="/">How it works</FooterLink>
              <FooterLink to="/">Testimonials</FooterLink>
              <FooterLink to="/">Careers</FooterLink>
              <FooterLink to="/">Investors</FooterLink>
              <FooterLink to="/">Terms of Service</FooterLink> */}
            {/* </FooterLinkItems> */}
            {/* <FooterLinkItems>
              <FooterLinkTitle>About us col2</FooterLinkTitle>
              <FooterLink to="/">How it works</FooterLink>
              <FooterLink to="/">Testimonials</FooterLink>
              <FooterLink to="/">Careers</FooterLink>
              <FooterLink to="/">Investors</FooterLink>
              <FooterLink to="/">Terms of Service</FooterLink>
            </FooterLinkItems> */}
          {/* </FooterLinksWrapper> */}
        </FooterLinksContainer>
        {/* Social Media */}
        <SocialMedia>
          <SocialMediaWrap>
            {/* <SocialLogo to="/" onClick={scrollToTop}>
              Little Seoul
            </SocialLogo> */}
            {/* <SocialIcons> */}
              {/* <SocialIconLink href="https://www.facebook.com/LittleSeoul.Mansfield/" target="_blank" aria-label="Facebook"> */}
                {/* <FaFacebook /> */}
              {/* </SocialIconLink> */}
              {/* <SocialIconLink href="https://www.instagram.com/littleseoul.mansfield/" target="_blank" aria-label="Instagram"> */}
                {/* <FaInstagram /> */}
/              {/* <SocialIconLink href="/" target="_blank" aria-label="Twitter">
                <FaTwitter />
              </SocialIconLink> */}
              {/* <SocialIconLink href="/" target="_blank" aria-label="Linkedin">
                <FaLinkedin />
              </SocialIconLink> */}
            {/* </SocialIcons> */}
          </SocialMediaWrap>
          <SocialMediaWrap>
            <WebsiteRights>little Seoul &#169; {new Date().getFullYear()} All rights reserved</WebsiteRights>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  )
}

export default Footer
