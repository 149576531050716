import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SideBtnWrap,
  SidebarRoute
} from './SidebarElements'

const Sidebar = ({isOpen, toggle}) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          {/* <SidebarLink to='about' onClick={toggle}>About</SidebarLink>
          <SidebarLink to='menu' onClick={toggle}>Menu</SidebarLink> */}
          <SidebarLink to='menu' onClick={toggle}>Menu</SidebarLink>
          <SidebarLink to='contact' onClick={toggle}>Contact</SidebarLink>
        </SidebarMenu>
        <SideBtnWrap>
          <SidebarRoute 
            as="a"
            href="https://www.foodbooking.com/api/fb/j9b0x"
            target="_blank" 
            aria-label="food booking"
            >Order Now
          </SidebarRoute>
        </SideBtnWrap>
        <SideBtnWrap>
          <SidebarRoute 
            as="a"
            href="https://www.ezcater.com/catering/pvt/little-seoul-korean-bbq-3?aff"
            target="_blank" 
            aria-label="ezcater"
            >Catering Order 
          </SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  )
}

export default Sidebar;
