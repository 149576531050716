import React, {useState} from 'react'
import Footer from '../components/Footer';
import HeroSection from '../components/HeroSection';
// import InfoSection from '../components/InfoSection';
// import { 
//   homeObjOne, 
//   homeObjTwo, 
//   homeObjThree 
// } from '../components/InfoSection/Data';
import Navbar from '../components/Navbar'
// import MenuItem from '../components/MenuItem';
import Sidebar from '../components/Sidebar'
// import { 
//   MenuItemH1,
//   MenuItemContainer
// } from '../components/MenuItem/MenuItemsElements';

import { 
  Img, 
  MenuContainer, 
  MenuWrapper, 
  Heading ,
  TopLine,
  SubTopLine
} from './StyleElements'

// import kimchiFries from '../images/Little_Seoul_Kimchi_Fries.jpg'
// import websiteTogoMenu from '../images/website_togo_menu.jpg'
// import verticalMenu from '../images/Vertical-Menu.webp'
import byob from '../images/BYOB.jpg'
// import byobMenu from '../images/byob_ls.webp'
import starterMenu from '../images/Starter.png'
import specialMenu from '../images/Special_Entree.jpg'
// const sharp = require('sharp');


const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection />
      {/* <InfoSection { ...homeObjTwo} /> */}

      <MenuContainer id="menu">
        <MenuWrapper>
        <TopLine>little Seoul</TopLine>
        <SubTopLine>Korean BBQ Inspired</SubTopLine>
        <Heading>Build Your Own</Heading>
          <Img src={byob} alt="Build your own menu" role="image" />
          <Img src={starterMenu} alt="Starter &amp Sides menu" role="image" 
            style={{paddingTop: '1rem', paddingBottom: '1rem'}}
          />
          <Img src={specialMenu} alt="Special Entree menu" role="image" 
            style={{paddingTop: '1rem', paddingBottom: '1rem'}}
          />
        </MenuWrapper>
      </MenuContainer>

      {/* <MenuItemContainer>
        <MenuItemH1>Starters</MenuItemH1>
        <MenuItem 
          title="Chicken Mandu"
          description="Deep fried chicken dumplings with Spicy Ponzu dipping"
          img={kimchiFries}
          />
          <MenuItem 
            title="Mini Kimchi Fries"
            description="Caramelize Kimchi, Onions, Cilantro, Shredded Cheddar &amp; Monterey Jack Cheese, Sesame seeds"
            img={kimchiFries}
            />
          <MenuItem 
            title="Chips &amp; Salsa"
            description="Tortilla chips with Homemade Red Salsa"
            img={kimchiFries}
          />
          <MenuItem 
            title="Chips &amp; Guacamole"
            description="Tortilla chips with Homemade Guacamole"
            img={kimchiFries}
            />
          <MenuItem 
            title="House Salad"
            description="Spring mix salad with house dressing"
            img={kimchiFries}
            />
          <MenuItem 
            title="Small Waffle fries"
            description="Deep fried Waffle french fries"
            img={kimchiFries}
            />
          <MenuItemH1>Specialty Entrees</MenuItemH1>
          <MenuItem 
            title="Little Seoul Kimchi Fries"
            description="Caramelize Kimchi, Onions, Cilantro, Shredded Cheddar &amp; Monterey Jack Cheese, Sesame seeds and Your Choice of Protein"
            img={kimchiFries}
            />
            <MenuItem 
              title="Loaded Nachos"
              description="Tortilla chips topped with your choice of Protein, Kimchi, Refried Pinto beans, Pico de Gallo, Jalapeno Guacamole, and Shredded Cheddar &amp; Monterey Jack Cheese"
              img={kimchiFries}
              />
      </MenuItemContainer> */}
      <Footer />
    </>
  )
}

export default Home
