import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// import { Route, Switch, Router } from "react-router";
import Home from './pages';

function App() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" component={Home} /> */}
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default App;
