import React, {useState, useEffect} from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { animateScroll as scroll } from 'react-scroll';
import { 
  Nav, 
  NavbarContainer, 
  NavLogo, 
  MobileIcon, 
  NavMenu, 
  NavItem, 
  NavLinks, 
  NavBtn,
  NavBtnLink,
  LogoImage
} from './NavbarElements'

import tigerHead from '../../images/tiger_logo.webp'

const Navbar = ({ toggle }) => {

  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  }

  return (
    <>
    <IconContext.Provider value={{ color: '#fff'}}>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to='/' onClick={toggleHome}> <LogoImage src={tigerHead} alt="Little Seoul Logo" role='image'></LogoImage>little Seoul </NavLogo>

          {/* <a href="https://freecodecamp.org" class="logo">
      <img src="https://s3.amazonaws.com/freecodecamp/freecodecamp_logo.svg" alt="freeCodeCamp logo">
    </a> */}

          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            {/* <NavItem>
              <NavLinks 
                to='about' 
                smooth={true} 
                duration={500} 
                spy={true} 
                exact="true" 
                offset={-80}
              >
                About
              </NavLinks>
              </NavItem>
            <NavItem>
              <NavLinks 
                to='services'
                smooth={true} 
                duration={500} 
                spy={true} 
                exact="true" 
                offset={-80}
              >
                Services
              </NavLinks>
            </NavItem> */}
            <NavItem>
              <NavLinks 
                to='menu'
                smooth={true} 
                duration={500} 
                spy={true} 
                exact="true" 
                offset={-80}
              >
                Menu
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks 
                to='footer'
                smooth={true} 
                duration={500} 
                spy={true} 
                exact="true" 
                offset={-80}
              >
                Contact
              </NavLinks>
            </NavItem>
          </NavMenu>
          <NavBtn>
            <NavBtnLink as="a" href="http://www.LittleSeoulTogo.com" target="_blank" aria-label="food booking">Order Now</NavBtnLink>
          </NavBtn>
          <NavBtn>
            <NavBtnLink as="a" href="https://www.ezcater.com/catering/pvt/little-seoul-korean-bbq-3?aff" target="_blank" aria-label="food booking">Catering Order</NavBtnLink>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </IconContext.Provider>
    </>
  )
}

export default Navbar;