import React, { useState } from 'react'
// import Video from '../../videos/video.mp4';
import { Button } from '../ButtonElements'
import { 
  HeroContainer, 
  HeroBg, 
  // VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from './HeroElements'


const HeroSection = () => {
  const [hover, setHover] = useState(false)

  const onHover = () => {
    setHover(!hover)
  }
  return (
    <HeroContainer id="home">
      <HeroBg />
        {/* <VideoBg autoPlay loop muted src={Video} type='video/mp4' /> */}
      {/* </HeroBg> */}
      <HeroContent>
        <HeroH1>Little Seoul Korean BBQ Inspired</HeroH1>
        <HeroP>Order Pick Up Now</HeroP>
        <HeroBtnWrapper>
        {/* <NavBtnLink href="https://www.foodbooking.com/api/fb/j9b0x" target="_blank" aria-label="food booking">Order Now</NavBtnLink> */}

          <Button 
            as="a"
            href="https://www.foodbooking.com/api/fb/j9b0x"
            target="_blank" 
            aria-label="food booking"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Order Now { hover ? <ArrowForward /> : <ArrowRight /> }
            </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  )
}

export default HeroSection;
